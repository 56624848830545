import React from "react";
import Div from "../Div";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import SocialWidget from "../Widget/SocialWidget";
import TextWidget from "../Widget/TextWidget";
import "./footer.scss";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const copyrightLinks = [
    {
      title: "Terms of Use",
      href: "https://whitepaper.vsion.io/legal/terms-of-use",
    },

    {
      title: "Privacy Policy",
      href: "https://whitepaper.vsion.io/legal/privacy-policy",
    },
  ];

  const serviceMenu = [
    {
      title: "SMART CONTRACT",
      href: "/",
    },
    {
      title: "DAPP DEVELOPMENT",
      href: "/",
    },
    {
      title: "WEBSITE DEVELOPMENT",
      href: "/",
    },
    {
      title: "NFT AND TOKEN",
      href: "/",
    },
    {
      title: "ANDROID AND IOS APP",
      href: "/",
    },
    {
      title: "MARKETPLACES",
      href: "/",
    },
  ];

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/logo.png"
                  logoAlt="Logo"
                  text="VsionLabs stands at the forefront of innovation as a leading software development lab specializing in tailor-made software solutions, Web3 development, and blockchain technology."
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-1 "></Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading="Services" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title="Contact Us" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">
              Copyright © {new Date().getFullYear()} VsionLabs. All rights
              reserved.
            </Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
